import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import { Link, navigate } from "gatsby";
import { useState, useCallback } from "react";
import Logo from "../assets/images/logo.png";
import {registerUser, registerWithGoogle, firebaseSignOut} from "../db/auth"
import {getCountries} from "../db/api"

const SignUp = () =>
{
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [error, setError] = useState("")

    const handleRegisterUser = useCallback(async () => {
        setError("");
        try {
            await registerUser(email, password, firstName, lastName);
            navigate("/register-info/");            
        } catch (e) {
            setError(e.message);
        }
    }, []);

    const handleRegisterWithGoogle = useCallback(async () => {
        setError("");
        try {
            await registerWithGoogle();
            navigate("/register-info");
        } catch (e) {
            setError(e.message);
        }
    }, []);

    return (<Layout>
        <SEO title="Register" />
        <Navbar />
        <PageBanner pageTitle="Register" />
        <div className="ptb-80">
            <div className="container">
                <div className="auth-form">
                    <div className="auth-head">
                        <Link to="/home">
                            <img src={Logo} />
                        </Link>
                        <p>Create a new account OR </p>
                        <button className="btn btn-primary" onClick={handleRegisterWithGoogle}>
                            Register with Google
                        </button>
                    </div>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" value = {email} onInput={(e)=>setEmail(e.target.value)} required/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control" id="exampleInputPassword1" value = {password} onInput={(e)=>setPassword(e.target.value)} required/>
                        </div>

                        {/* <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" id="ConfirmPassword" />
                        </div> */}

                        <div className="mb-3">
                            <label className="form-label">First name</label>
                            <input type="text" className="form-control" id="firstName" value = {firstName} onInput={(e)=>setFirstName(e.target.value)}required/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Last name</label>
                            <input type="text" className="form-control" id="lastName" value = {lastName} onInput={(e)=>setLastName(e.target.value)} required/>
                        </div>

                        <div>{error}</div>

                        <button type="button" className="btn btn-primary" onClick={handleRegisterUser}>Next</button>
                    </form>

                    {/* <div className="foot">
                        <p>Already have an account yet? <Link to="/login">Login</Link></p>
                    </div> */}
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
    )
}

export default SignUp;
